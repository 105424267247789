import axios from 'axios'
import { encodeLoad, decodeLoad } from '@/lib/base64.js'

import { useUserStore } from '@/stores/userstore.js'
import { useAdminStore } from '@/stores/adminstore.js'

import dayjs from '@/plugins/dayjs.js'

// import { CreateHypatiaJWE } from '@/lib/createJWT'

// import { base64 } from 'base64-url'

// import { jwt } from 'jsonwebtoken'
// import * as jose from 'jose'

const ENGAGEURL = 'https://dsrl.eu:3081/engage-backend/'

export const adminActions = {
  setTest(text) {
    console.log('set TEST got called (%s)', text)
    this.test = text
  },
  setSelectedService(serviceid) {
    this.services.add(serviceid)
  },
  /* Should be the same i both adminweb and user*/
  isActiveService(serviceid) {
    return this.services.has(serviceid)
  },
  setActiveNavItem(navItem) {
    this.activenavitem = navItem
  },
  setUiLevel(level) {
    this.uilevel = level
  },
  setLang(lang) {
    this.lang = lang
  },
  setServername(server) {
    this.servername = server
  },
  setRegistryUrl(server) {
    this.registryurl = checkRegistryURL(server)
  },
  setServerUrl(server) {
    console.info('<<<<<<<<<<<<<<< SET SERVER URL >>>>>>>>>>>')
    console.info(server)
    this.serverurl = checkRegistryURL(server)
    console.info(this.serverurl)
  },
  setEnvironment(environment) {
    this.environment = environment
  },
  //  setDataFrominfoUrl(payload) {
  async setDatafrominfourl(payload) {
    console.log('fetchDataFrominfoUrl got called ')
    console.log(payload)
    //  console.log(JSON.stringify(payload))

    return new Promise((resolve) => {
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: this.getSid
      }
      const instance = this.getAxiosConnection('test/')
      instance
        //        .post("getDataFromInfoUrl/infoUrl", pLoad)
        .get('', pLoad)
        .then((result) => {
          console.log('getDataFromInfoUrl returned')
          console.log(result.data)
          resolve({
            success: true,
            html: decodeLoad(result.data.dgraphresult.htmlData)
          })
        })
        .catch((error) => {
          console.log('COULD NOT GET URL DATA')
          resolve({
            success: false,
            error: true,
            errorMsg: JSON.stringify(error)
          })
        })
    }) // promise
  },
  async setSchema() {
    console.log('SET MEMAS Schema got called ! ')
    return new Promise((resolve, reject) => {
      // let serverUrl = this.serverurl
      // let accessClientToken = userStore.serveraccesstoken
      // let accessClientToken = context.getters.getIdToken
      // console.info('========= idToken !!: ' + accessClientToken)
      let pLoad = {
        p1: this.sid,
        gogl: true
      }
      console.log('PLOAD IS ', pLoad)
      //      const instance = this.getServerConnection('dgraphschema/setschema')
      const instance = this.getAxiosConnection('dgraphschema/')

      console.log('WE HAVE INSTANCE (%s) (%j)', this.serverurl, instance)
      // console.log('Accesstoken (%s)', userStore.serveraccesstoken)
      instance
        .post('setschema', pLoad)
        .then((res) => {
          console.log('RES FROM Set Schema', res)
          resolve({ success: true, data: res.data })
        })
        .catch((error) => {
          console.log('GET SERVER INSTANCE ERROR ')
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('*** ERRRO ***')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            console.log('*** ERRRO REQUST ***')
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
          console.log('*** ERROR *** CONFIG')
          console.log(error.config)
          reject({ success: false, error: error.message })
        })
      /*
      instance
        .post('', pLoad)
        .then((response) => {
          console.log('setSchema response: ')
          resolve(response)
        })
        .catch((error) => {
          console.error('updateSchema had an error: ', error)
          reject(new Error('updateSchema had an error: ', error))
        })
        */
    }) // promise
  },
  async setSchemaFOO() {
    console.log('SET MEMAS Schema Foo got called ! ')
    return new Promise((resolve, reject) => {
      // let serverUrl = this.serverurl
      // let accessClientToken = userStore.serveraccesstoken
      // let accessClientToken = context.getters.getIdToken
      // console.info('========= idToken !!: ' + accessClientToken)
      let pLoad = {
        p1: this.sid,
        gogl: true
      }
      console.log('PLOAD IS ', pLoad)
      const instance = this.getServerConnection('foo')
      instance
        .get('', pLoad)
        .then((res) => {
          console.log('RES FROM Set schema', res)
          resolve({ success: true, data: res.data })
        })
        .catch((error) => {
          console.log('GET SERVER INSTANCE ERROR ')
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('*** ERRRO ***')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            console.log('*** ERRRO REQUST ***')
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
          console.log('*** ERROR *** CONFIG')
          console.log(error.config)
          resolve({ error: true, success: false, errorMsg: error.message })
        })
      /*
      instance
        .post('', pLoad)
        .then((response) => {
          console.log('setSchema response: ')
          resolve(response)
        })
        .catch((error) => {
          console.error('updateSchema had an error: ', error)
          reject(new Error('updateSchema had an error: ', error))
        })
        */
    }) // promise
  },
  async getPingServer() {
    console.log('GET PING SERVER (%s)', this.serverurl)
    return new Promise((resolve, reject) => {
      const pingserver = this.getAxiosConnection2(
        this.serverurl,
        'kubeheartbeat/'
      )
      pingserver
        .get('isalive')
        .then((result) => {
          console.log('getPingServer er: (' + JSON.stringify(result) + ')')
          resolve(result.data)
        })
        .catch((error) => {
          console.error('*** ERROR *** getPingServer had some errors:', error)
          reject({
            errorMsg: '*** ERROR *** getPingServer had some errors:' + error
          })
        })
    }) // promise
  },
  async addRegistryUrl() {
    console.log('addRegistryUrl got called:(' + this.servername + ')')
    // let serverUrl = payload.serverURL
    return new Promise((resolve, reject) => {
      // getURLHost(serverUrl)
      const clientServer = this.getAxiosConnection3(
        this.servername,
        'lsregistry/'
      )
      // console.log('============ got client Server ======== (' + serverUrl + ')')
      clientServer
        .get('index.json')
        .then((result) => {
          console.log(
            'REGISTRY Server er: (' + JSON.stringify(result.data) + ')'
          )
          let registryUrl = ''
          if (result.data.registry_server_port === '') {
            registryUrl = result.data.registry_server
          } else {
            registryUrl =
              result.data.registry_server +
              ':' +
              result.data.registry_server_port
          }
          // context.commit('setServerUrl', payload)
          localStorage.setItem('memasregistry', checkRegistryURL(registryUrl))
          this.registryurl = checkRegistryURL(registryUrl)
          const saServer = this.getAxiosConnection3(this.registryurl, 'ls/')
          console.log(
            '>>> GETTING SERVER FROM REGISTRY (%sls/memasserver)',
            this.registryurl
          )
          saServer
            .get('memasserver')
            .then((result) => {
              console.log('setServerUrl/memasServer/get got:')
              console.log(result)
              // console.log(result.data)
              if (result.data.error !== undefined) {
                reject({
                  error: '*** ERROR *** no result from registry service.',
                  errormsg:
                    'The Learning Registry service need to be configured !'
                })
              }
              let serverURL = checkURL(result.data.hostname, result.data.port)
              // console.log('---- serverURL (' + serverURL + ')')
              localStorage.setItem('memasserver', serverURL)
              this.setServerUrl(serverURL)
              resolve({
                result: serverURL
              })
            })
            .catch((error) => {
              console.error(
                '*** ERROR *** addRegistryUrl/getting memas-server info (' +
                  error +
                  ')'
              )
              reject({
                error:
                  '*** ERROR *** addRegistryUrl/getting memas-server info :',
                errormsg: error
              })
            })
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** addRegistryUrl getting registry service: '
          )
          console.error(error)
          reject({
            error: '*** ERROR *** addRegistryUrl/getting registry service: ',
            errormsg: error
          })
        })
    }) // promise
    // context.commit('setServerUrl', payload.serverURL)
  },
  testPromise(payload) {
    console.log('testPromise got called ')
    console.log(payload)
    //  console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      resolve({
        success: false,
        error: true,
        message: 'hopp og hei'
      })
    }) // promise
  },
  async prepareEngageActivityData() {
    return new Promise((resolve) => {
      let uStore = useUserStore()
      let primaryUser = uStore.primaryuser
      console.log('PRIMARY USER (%j)', primaryUser)
      this.primaryUserUID = primaryUser.pu_userid || '' // 'c0d76813-c0ab-4fdb-8613-770259d89209' // primaryUser.pu_userid
      let apiUrl = ENGAGEURL + 'fitbit/summary/'
      let fitBitDataConn = this.getAxiosConnection3(apiUrl, this.primaryUserUID)
      fitBitDataConn
        .get()
        .then((res) => {
          console.log('getFitBitData Returned (%j)', res)
          let resData = res.data || {}
          console.log('res data')
          console.log(resData)
          let eData = resData
          let activityData = eData.activity || [{}]
          let heartData = eData.heart || [{}]
          let sleepData = eData.sleep || [{}]
          let activityMap = new Map()
          for (let i = 0; i < activityData.length; i++) {
            let activity = activityData[i]
            let activityDate = activity.date
            let activityLevel = {}
            activityLevel.sedate = activity.sedentaryMinutes
            activityLevel.lightly = activity.lightlyActivityMinutes
            activityLevel.fairly = activity.fairlyActivityMinutes
            activityLevel.very = activity.veryActivityMinutes
            activityMap.set(activityDate, activityLevel)
          }
          let heartMap = new Map()
          for (let i = 0; i < heartData.length; i++) {
            let heart = heartData[i] || {}
            //        console.log('HEARTH (%j)', heart)
            let hDate = heart.dateTime
            let heartHourValues = heart.general || []
            let heartHourMap = new Map()
            for (let j = 0; j < heartHourValues.length; j++) {
              let hourValue = heartHourValues[j]
              let zones = hourValue.zones || []
              for (let k = 0; k < zones.length; k++) {
                let zone = zones[k]
                let zoneName = zone.name
                if (heartHourMap.has(zoneName)) {
                  let zoneMinutes = heartHourMap.get(zoneName)
                  heartHourMap.set(zoneName, zoneMinutes + zone.minutes)
                } else {
                  heartHourMap.set(zoneName, zone.minutes)
                }
                // heartHourMap.set(zoneName, zoneMinutes)
              }
              // console.log('HOUR VALUE (%j)', hourValue)
              //let hourDay = hourValue.hour
              // heartHourMap.set(hourDay,hourValue.zones)
            }
            heartMap.set(hDate, heartHourMap)
          }
          let sleepMap = new Map()
          for (let i = 0; i < sleepData.length; i++) {
            let sStages = {}
            let sleep = sleepData[i]
            let sleepDate = sleep.dateTime
            let sSummary = sleep.summary || []
            let sleepDetails = sSummary[0] || {}
            let sleepStages = sleepDetails.stages || []
            for (let j = 0; j < sleepStages.length; j++) {
              let sDetail = sleepStages[j]
              if (sDetail.name === 'deep') {
                sStages.deep = sDetail.value
              }
              if (sDetail.name === 'light') {
                sStages.light = sDetail.value
              }
              if (sDetail.name === 'rem') {
                sStages.rem = sDetail.value
              }
              if (sDetail.name === 'wake') {
                sStages.wake = sDetail.value
              }
            }
            sleepMap.set(sleepDate, sStages)
          }
          // this.timeRadarData = this.getChart2()
          resolve({
            success: true,
            heartValues: heartMap,
            activityValues: activityMap,
            sleepValues: sleepMap
          })
        })
        .catch((err) => {
          console.error('Error in getFitBitData: ', err)
          resolve({
            success: false,
            error: true,
            errorMsg: 'Error in getFitBitData: ' + err
          })
        }) // fitbitdataConn
    }) // Promise
  },
  async prepareMLData() {
    return new Promise((resolve) => {
      let uStore = useUserStore()
      let primaryUser = uStore.primaryuser
      console.log('PRIMARY USER (%j)', primaryUser)
      this.primaryUserUID = primaryUser.pu_userid || '' // 'c0d76813-c0ab-4fdb-8613-770259d89209' // primaryUser.pu_userid
      let apiUrl = ENGAGEURL + 'fitbit/prediction/daily-fragmentation/'
      // let apiUrl = ENGAGEURL + 'fitbit/prediction/'
      // this.primaryUserUID = 'c211ad68-c30e-40a9-88d9-07465f6cff86'
      let fitBitDataConn = this.getAxiosConnection3(apiUrl, this.primaryUserUID)
      fitBitDataConn
        .get()
        .then((res) => {
          console.log('get ML Data Returned (%j)', res)
          let resData = res.data || {}
          console.log('res data')
          console.log(resData)
          let mlMap = new Map()

          for (let i = 0; i < resData.length; i++) {
            let ml = resData[i]
            let mlDate = dayjs(ml.startDateTime).format('ddd DD MMM')
            console.log('mlData (%s)', mlDate)
            let mlLevel = {}
            switch (ml.category) {
              case 'Low':
                mlLevel.low = ml.fragmentationIndex
                break
              case 'High':
                mlLevel.high = ml.fragmentationIndex
                break
              case 'Very High':
                mlLevel.veryhigh = ml.fragmentationIndex
                break
              default:
                mlLevel.medium = ml.fragmentationIndex
            }
            mlMap.set(mlDate, mlLevel)
            /*{
              level: mlLevel,
              fragementation: mlFragmentation
            })*/
          }

          // this.timeRadarData = this.getChart2()
          resolve({
            success: true,
            mlValues: mlMap
          })
        })
        .catch((err) => {
          console.error('Error in getFitBitData: ', err)
          resolve({
            success: false,
            error: true,
            errorMsg: 'Error in getFitBitData: ' + err
          })
        }) // fitbitdataConn
    }) // Promise
  },
  getServerConnection(path) {
    const userStore = useUserAdminStore()
    let url = this.serverurl
    let accessClientToken = userStore.serveraccesstoken
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    console.log('getAxiosConnection url(' + checkRegistryURL(url) + path + ')')
    return axios.create({
      baseURL: checkRegistryURL(url) + path,
      timeout: 10000,
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 8, // default
      'Accept-Encoding': 'gzip, compress',
      withCredentials: true,
      jar: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessClientToken}`,
        Accept: 'application/json',
        memasclient: 'betterhealthforall', // for Cloud armor firewall rules
        'Access-Control-Allow-Credentials': true
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': '*',
        // 'Access-Control-Allow-Credentials': 'true'
      }
    })
  },
  getAxiosConnection(path) {
    const adminStore = useAdminStore()
    const userStore = useUserStore()
    let url = adminStore.serverurl
    let accessClientToken = userStore.serveraccesstoken
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    console.log('getAxiosConnection url(' + url + ') path(' + path + ')')
    return axios.create({
      baseURL: checkRegistryURL(url) + path,
      timeout: 30000,
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 8, // default
      'Accept-Encoding': 'gzip, compress',
      withCredentials: true,
      jar: true,
      headers: {
        Authorization: 'Bearer ' + accessClientToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        memasclient: 'betterhealthforall' // for Cloud armor firewall rules
      }
    })
  },
  getAxiosConnectionImage(path) {
    const adminStore = useAdminStore()
    const userStore = useUserStore()
    let url = adminStore.serverurl
    let accessClientToken = userStore.serveraccesstoken
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    console.log('getAxiosConnectionImage url(' + url + ') path(' + path + ')')
    return axios.create({
      baseURL: checkRegistryURL(url) + path,
      timeout: 60000, // 1 minute
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 8, // default
      'Accept-Encoding': 'gzip, compress',
      withCredentials: true,
      jar: true,
      headers: {
        Authorization: 'Bearer ' + accessClientToken,
        memasclient: 'betterhealthforall', // for Cloud armor firewall rules
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      }
    })
  },
  getAxiosConnection2(url, path) {
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    console.log('getAxiosConnection url(' + url + ') path(' + path + ')')
    return axios.create({
      baseURL: checkRegistryURL(url) + path,
      timeout: 15000,
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 3, // default
      'Accept-Encoding': 'gzip, compress',
      headers: {
        memasclient: 'betterhealthforall',
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  },
  getAxiosConnection3(url, path) {
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    console.log('getAxiosConnection3 url(' + url + path + ')')
    return axios.create({
      baseURL: checkRegistryURL(url) + path,
      timeout: 15000,
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 3, // default
      'Accept-Encoding': 'gzip, compress',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  },
  getEngageAxiosConnection_NOTUSED(url, path) {
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    console.log('getAxiosConnection url(' + url + ') path(' + path + ')')
    return axios.create({
      baseURL: checkRegistryURL(ENGAGEURL) + path,
      timeout: 5000,
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 3, // default
      'Accept-Encoding': 'gzip, compress',
      headers: {
        'Access-Control-Allow-Origin': 'https://dsrl.eu:3081',
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  },
  getDiagnosis(lang) {
    /*Heart failure (oversatt fra norsk)
    Heart failure (Romania)
    COPD
    Diabetes
    Heart attack
    Aorta stenosis (Romania)
  */
    console.log('GET DIAGNOSIS (%s)', lang)
    console.log(lang)
    switch (lang) {
      case 'en':
        return [
          {
            label: 'heartfailure',
            value: 'diagnosis.heartfailure', // is mapped $t('services.today')
            tooltip: 'diagnosis.heartfailuretooltip',
            default: false
          }
        ]
      case 'fr':
        return [
          {
            label: 'heartfailure',
            value: 'diagnosis.heartfailure', // is mapped $t('services.today')
            tooltip: 'diagnosis.heartfailuretooltip',
            default: false
          }
        ]
      case 'it':
        return [
          {
            label: 'infection',
            value: 'diagnosis.infection', // is mapped $t('services.today')
            tooltip: 'diagnosis.infectiontooltip',
            default: false
          }
        ]
      case 'ro':
        return [
          {
            label: 'heartfailure',
            value: 'diagnosis.heartfailure', // is mapped $t('services.today')
            tooltip: 'diagnosis.heartfailuretooltip',
            default: false
          },
          {
            label: 'aortastenosis',
            value: 'diagnosis.aortastenosis', // is mapped $t('services.today')
            tooltip: 'diagnosis.aortastenosistooltip',
            default: false
          }
        ]
      case 'nb':
      default:
        return [
          {
            label: 'heartfailure',
            value: 'diagnosis.heartfailure', // is mapped $t('services.today')
            tooltip: 'diagnosis.heartfailuretooltip',
            default: true
          },
          {
            label: 'heartattack',
            value: 'diagnosis.heartattack',
            tooltip: 'diagnosis.heartattacktooltip',
            default: false
          },
          {
            label: 'copd', // kols
            value: 'diagnosis.copd',
            tooltip: 'diagnosis.copdtooltip',
            default: false
          },
          {
            label: 'diabetes1',
            value: 'diagnosis.diabetes1',
            tooltip: 'diagnosis.diabetes1tooltip',
            default: false
          },
          {
            label: 'diabetes2',
            value: 'diagnosis.diabetes2',
            tooltip: 'diagnosis.diabetes2tooltip',
            default: false
          },
          {
            label: 'aortastenosis',
            value: 'diagnosis.aortastenosis',
            tooltip: 'diagnosis.aortastenosistooltip',
            default: false
          },
          {
            label: 'obesity',
            value: 'diagnosis.obesity',
            tooltip: 'diagnosis.obesitytooltip',
            default: false
          },
          {
            label: 'blodpressure',
            value: 'diagnosis.blodpressure',
            tooltip: 'diagnosis.blodpressuretooltip',
            default: false
          },
          {
            label: 'cognitiveloss',
            value: 'diagnosis.cognitiveloss',
            tooltip: 'diagnosis.cognitivelosstooltip',
            default: false
          },
          {
            label: 'infection',
            value: 'diagnosis.infection',
            tooltip: 'diagnosis.infectiontooltip',
            default: false
          }
        ]
    } // switch
  }
}

function checkRegistryURL(url) {
  if (url.length > 0) {
    let lastChar = url.charAt(url.length - 1)
    // console.log('CHECK REGISTRY URL (%o) lastchar(%o)', url, lastChar)
    if (lastChar === '/') {
      return url
    } else {
      return url + '/'
    }
  } else {
    console.error('NO URL PROVIDED !!! ')
    return ''
  }
}

function checkURL(url, port) {
  console.log('checkURL : (' + url + ') (' + port + ')')
  let newUrl = ''
  if (port === null || port === undefined) {
    port = ''
  }
  if (url.slice(0, 5) === 'https' && port === '') {
    newUrl = url
  } else if (url.slice(0, 5) === 'https' && port !== '') {
    newUrl = url + ':' + port
  } else if (url.slice(0, 4) === 'http' && port === '') {
    newUrl = url
  } else if (url.slice(0, 4) === 'http' && port !== '') {
    newUrl = url + ':' + port
  } else if (port === '443') {
    newUrl = 'https://' + url
  } else if (port !== '') {
    newUrl = 'http://' + url + ':' + port
  } else {
    newUrl = 'http://' + url
  }
  return newUrl
}

/*
async function getServerSecret() {
  console.log('GET SERVER Secret GOT CALLED !')
  console.log('=== getServerSecret ')
  return new Promise((resolve, reject) => {
    // let accessClientToken = context.getters.getIdToken
    // console.error('========= idToken !!: ' + accessClientToken)
    // let serverUrl = context.getters.getServerUrl // No context outside action-vuex-store context...
    let serverUrl = this.serverurl
    const sysinfo = getAxiosConnection('sysinfo')
    sysinfo
      .get('/jwtsecret', {
        headers: {
          //    'Access-Control-Allow-Headers': 'access-control-allow-origin',
          //    'Access-Control-Allow-Headers': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        jar: true
      })
      .then((result) => {
        console.log(
          'JwtSecret er: (' + JSON.stringify(result.data.secret) + ')'
        )
        console.log('getServerSecret returned (%J)', result)
        //  localStorage.setItem('hypsaserversecret', result.data.secret)
        console.log('publicKey: ' + result.data.publicKey)
        resolve(result.data) // {'secret': something }
      })
      .catch((error) => {
        console.error('*** ERROR *** getServerSECRET had some errors:', error)
        reject(
          new Error({
            error: error
          })
        )
      })
  })
}
*/
