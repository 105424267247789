<template>
    <v-app id="app">
        <NavigationSystem />
        <v-main class="content">
            <router-view />
            <v-snackbar
                v-model="updateExists"
                location="top"
                :close-on-content-click="true"
                :timeout="-1"
                color="var(--color-brand-100)"
            >
                {{ $t('gui.updateavailable') }}
                <v-btn text @click="refreshApp">
                    {{ $t('gui.update') }}
                </v-btn>
            </v-snackbar>
            <v-snackbar
                v-model="isOffline"
                location="top"
                :timeout="snackbarTimeout"
                color="var(--color-offline-100)"
            >
                <v-card flat color="var(--color-offline)">
                    <v-card-title class="snackbarHeading">
                        {{ $t('gui.offlineheading') }}
                    </v-card-title>
                    <v-card-text class="snackbarText">
                        {{ $t('gui.offlinetext') }}
                    </v-card-text>
                </v-card>
            </v-snackbar>
            <v-snackbar
                v-model="isOnline"
                location="top"
                :timeout="snackbarTimeout"
                color="var(--color-online-100)"
                transition="v-fab-transition"
            >
                <v-progress-linear
                    color="var(--color-brand)"
                    :close-on-content-click="true"
                    :model-value="incrementSeconds"
                    height="5"
                    rounded
                />
                <v-card flat color="var(--color-online)">
                    <v-card-title class="snackbarHeading">
                        {{ $t('gui.onlineheading') }}
                    </v-card-title>
                    <v-card-text class="snackbarText">
                        {{ $t('gui.onlinetext') }}
                    </v-card-text>
                </v-card>
            </v-snackbar>
            <v-snackbar
                v-model="hasUpdate"
                location="top"
                :close-on-content-click="true"
                :timeout="snackbarTimeout"
                color="var(--color-offline-100)"
            >
                <v-card flat color="var(--color-offline)">
                    <v-card-title class="snackbarHeading">
                        {{ $t('gui.updateavailable') }}
                    </v-card-title>
                    <v-card-text class="snackbarText">
                        {{ $t('gui.offlinetext') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="refreshApp">
                            {{ $t('gui.update') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-snackbar>
        </v-main>
        <FooterMenu />
    </v-app>
</template>

<script>
// import { RouterLink, RouterView } from "vue-router";
import { useAdminStore } from '@/stores/adminstore.js'

import FooterMenu from '@/components/menues/FooterMenu.vue'
import update from './mixins/update'

import { registerSW } from 'virtual:pwa-register'

const updateSW = registerSW({
    immediate: true,
    onNeedRefresh() {
        updateExists = true
    },
    onOfflineReady() {}
})

import NavigationSystem from '@/components/menues/NavigationSystem.vue'
export default {
    name: 'App',
    components: {
        FooterMenu,
        //  DoLogIn: () => import('@/components/menues/DoLogIn.vue'),
        NavigationSystem // : () => import("@/components/menues/NavigationSystem.vue"),
    },
    mixins: [update],
    beforeRouteEnter(to, from) {
        this.$info('------- APP VUE BEFORE ENTER -------- (%s) (%s)', to, from)
    },
    setup() {
        const adminStore = useAdminStore()
        return {
            // you can return the whole store instance to use it in the template
            adminStore
        }
    },
    data() {
        return {
            errorCapture: false,
            snackbarTimeout: -1,
            isOnline: false,
            isOffline: false,
            hasUpdate: false,
            incrementSeconds: 0
        }
    },
    beforeMount() {
        console.log('APP BEFORE MOUNT <<<<<<<<<<<<< (%s)', location.hostname)
        let aStore = useAdminStore()
        let serverUrl = localStorage.getItem('memasserver') || ''
        let registryUrl = localStorage.getItem('memasregistry') || ''
        var userLang = navigator.language || navigator.userLanguage
        console.info('BROWSER LANG IS (%s)', userLang)
        let localLang = localStorage.getItem('lang') || userLang
        let langComponents = userLang.split('-')
        if (langComponents.length > 0) {
            userLang = langComponents[0]
        }
        if (localLang !== userLang) {
            userLang = localLang
        }
        console.info('BROWSER LANG IS (%s) (%s) (%j)', localLang, userLang, langComponents)
        aStore.setLang(userLang)
        localStorage.setItem('lang', userLang)
        this.$root.$i18n.locale = userLang
        this.$i18n.locale = userLang
        this.$date.locale(userLang)

        console.info('*** MISSING SOME ESENNTIAL REGISTRY INFORMATION ***')
        // @todo fix set to nothing...
        // localStorage.setItem('hypregistry', 'https://www.hypatia.no')
        // this.$store.dispatch('addRegistryUrl', 'https://www.ataa.no')
        if (location.hostname === 'adminweb.eu') {
            this.adminStore.setServername('https://www.ataa.no/')
            //  } else if (os.hostname() === 'memas-adminweb.ataa.no') {
            //    this.$store.dispatch('addRegistryUrl', 'https://www.ataa.no/stage/')
        } else if (location.hostname === 'stage.adminweb.eu') {
            this.adminStore.setServername('https://www.ataa.no/stage')
            //  } else if (os.hostname() === 'memas-adminweb.ataa.no') {
            //    this.$store.dispatch('addRegistryUrl', 'https://www.ataa.no/stage/')
        } else if (location.hostname === 'localhost') {
            console.log('WE HAVE LOCALHOST (%s)', location.hostname)
            this.adminStore.setServername('http://localhost/')
            // this.adminStore.setServername('https://www.ataa.no/stage')
        }
        // this.adminStore.setServername(registryUrl)
        this.adminStore.setRegistryUrl(registryUrl)
        this.adminStore.setServerUrl(serverUrl)
        this.adminStore
            .addRegistryUrl()
            .then(() => {
                this.$router
                    .push({
                        path: '/'
                    })
                    .catch(() => {})
            })
            .catch((error) => {
                this.$router
                    .push({
                        path: '/'
                        //      path: '/Error'
                    })
                    .catch(() => {})
            })

        if (location.hostname === 'adminweb.eu') {
            this.adminStore.setEnvironment('prod')
        } else if (location.hostname === 'stage.adminweb.eu') {
            this.adminStore.setEnvironment('stage')
        } else if (location.hostname === 'localhost') {
            this.adminStore.setEnvironment('dev')
        }
    },
    mounted() {
        window.addEventListener('offline', () => {
            // Update your UI to reflect that there's no connection.
            console.warn('*** WARN *** we are OFFLINE !')
            this.snackbarTimeout = -1
            this.isOnline = false
            this.hasUpdate = false
            this.isOffline = true
        })
        window.addEventListener('online', () => {
            // Update your UI to reflect that the connection is back.
            console.warn('*** WARN *** we are back ONLINE !')
            this.incrementSeconds = 0
            this.snackbarTimeout = 8000
            this.isOffline = false
            this.hasUpdate = false
            this.isOnline = true
            this.countSeconds()
        })
        window.addEventListener('swUpdate', () => {
            // Update your UI to reflect that the connection is back.
            console.warn('*** WARN *** we have Update !')
            this.incrementSeconds = 0
            this.snackbarTimeout = 8000
            this.isOffline = false
            this.isOnline = false
            this.hasUpdate = true
            // this.countSeconds();
        })
    },
    methods: {
        countSeconds() {
            setInterval(() => {
                let sec = this.incrementSeconds + 1
                this.incrementSeconds = sec
            }, 130)
        },
        // https://vite-pwa-org.netlify.app/guide/prompt-for-update.html
        onNeedRefresh() {
            console.log('******* NEEED REFRESH ******* (app.vue)')
            this.updateExists = true
            updateSW()
        },
        onOfflineReady() {
            console.log('******** ON OFFLINE READY ******* (app.vue)')
        }
    }
}
</script>

<style scoped>
.content {
    margin-top: 2vh;
}
</style>
