<template>
    <v-footer
        v-if="getLoggedIn()"
        :app="true"
        height="40px"
        color="var(--color-brand-200)"
    >
        <!-- IS AUTHENTICATED -->
        <v-btn variant="text" size="small" v-html="getEnvText()" />
        <v-btn
            variant="text"
            size="small"
            class="nametext"
            :text="loggedInAs()"
        />
    </v-footer>
    <v-footer v-else app height="20px" color="var(--color-brand-200)">
        <v-btn variant="text" size="small" v-html="getEnvText()" />
    </v-footer>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { useAdminStore } from '@/stores/adminstore.js'
import { useUserStore } from '@/stores/userstore.js'

import app from '../../../package.json'

export default {
    name: 'FooterMenu',
    // props: ['auth', 'authenticated'],
    data() {
        return {
            flagComponentsMap: new Map()
        }
    },
    mounted() {},
    beforeMount() {},
    methods: {
        ...mapState(useUserStore, ['getLoggedIn', 'getDisplayName']),
        getEnvText() {
            return `&copy; v(${this.getEnv()})`
        },
        loggedInAs() {
            var displayName = this.getDisplayName()
            return displayName
        },
        getEnv() {
            return app.version
        }
    }
}
</script>

<style lang="css">
.footer {
    text-align: left;
    font-size: calc(0.6em + 1vmin);
    color: rgb(100, 100, 100);
}

.footerinfo {
    text-align: left;
    font-size: calc(0.6em + 1vmin);
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(100, 100, 100);
}

.languagechooser {
    text-align: left;
    font-size: calc(0.6em + 1vmin);
    margin-left: 20px;
    color: rgb(100, 100, 100);
}

.hostinfo {
    padding-left: 20px;
    text-align: left;
    font-size: calc(0.6em + 0.7vmin);
    color: rgb(100, 100, 100);
}

.domainserver {
    padding-left: 10px;
    font-size: calc(0.3em + 0.3vmin);
    color: rgb(100, 100, 100);
}

.nametext {
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
}
</style>
